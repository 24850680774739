import React, { useContext, useEffect, useState } from "react";
import { produtos } from "./JsonProdutos";
import style from "./Produtos.module.css";
import Modal from "../Modal/Modal";
import { QtdContext } from "../../context/qtdContext";
import { useNavigate } from "react-router-dom";
import Title from '../Title'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import ButtonsModal from "../Modal/ButtonsModal/ButtonsModal";
import Button from "../Button/Button";
// import { BiSolidDonateHeart } from "react-icons/bi";
import ModalDonatePriuts from "../Pages/DashBoard/Components/Modal/ModalDonatePriuts";
const Produtos = ({ amount }) => {
  const [imageId, setImageId] = useState(null);
  const { shops, setShops, productDots, serverProd, produtosSelecionados, opt, donatePriutModal, setDonatePriutModal, tabs, setTabs, setModalCart, setProdutosSelecionados } = useContext(QtdContext);
  const navigate = useNavigate()
  const shops2 = shops.filter(pr => pr.showed)
  const drydey = serverProd.filter((prod) => prod.kategory.includes("мясные консервы") && prod.showed)
  // console.log(drydey)
  const test = ['Говядина с печенью 325г', 'Говядина с печенью 525г', 'Говядина с рубцом 250г', '"Сытный обед" Говядина с рубцом 750 г', 'Говядина и конина с тыквой и зеленым яблоком 750 г', 'Говядина с рубцом "Сытный обед" 340 г', 'Говядина с гречкой 525 г', 'Говядина с потрошками 525 г', 'Говядина с бурым рисом 525 г']
  const premium = drydey.filter((prod) => test.includes(prod.name)).sort((a, b) => {
    // Сначала сортируем по условию scladqty > 0
    let maximumA = 0
    let maximumB = 0
    for (let i = 0; i < shops2.length; i++) {
      const scladqtyA = productDots.find(pd => shops2[i].showed && pd.prod_sh == a.shcode && pd.prod_id == a.id && shops2[i].id == pd.shop_id)
      const scladqtyB = productDots.find(pd => shops2[i].showed && pd.prod_sh == b.shcode && pd.prod_id == b.id && shops2[i].id == pd.shop_id)
      if (scladqtyA) {
        maximumA = maximumA + scladqtyA.scladqty
        // console.log(shops2[i], scladqtyA)
      }
      if (scladqtyB) {
        maximumB = maximumB + scladqtyB.scladqty
        // console.log(shops2[i], scladqtyB)
      }
    }
    if (maximumA > 0 && maximumB === 0) return -1;
    if (maximumA === 0 && maximumB > 0) return 1;

    // Если оба объекта в одной группе, сортируем по name
    return a.name.localeCompare(b.name);
  });
  const premiumSuper = drydey.filter((prod) => !test.includes(prod.name)).sort((a, b) => {
    // Сначала сортируем по условию scladqty > 0
    let maximumA = 0
    let maximumB = 0
    for (let i = 0; i < shops2.length; i++) {
      const scladqtyA = productDots.find(pd => shops2[i].showed && pd.prod_sh == a.shcode && pd.prod_id == a.id && shops2[i].id == pd.shop_id)
      const scladqtyB = productDots.find(pd => shops2[i].showed && pd.prod_sh == b.shcode && pd.prod_id == b.id && shops2[i].id == pd.shop_id)
      if (scladqtyA) {
        maximumA = maximumA + scladqtyA.scladqty
        // console.log(shops2[i], scladqtyA)
      }
      if (scladqtyB) {
        maximumB = maximumB + scladqtyB.scladqty
        // console.log(shops2[i], scladqtyB)
      }
    }
    if (maximumA > 0 && maximumB === 0) return -1;
    if (maximumA === 0 && maximumB > 0) return 1;

    // Если оба объекта в одной группе, сортируем по name
    return a.name.localeCompare(b.name);
  });
  const removeItem = async (itemId) => {
    const prodRemove = produtosSelecionados.filter(produtoid => produtoid.id !== itemId)
    setProdutosSelecionados(prodRemove)
    localStorage.setItem("produtosSelecionados", JSON.stringify(prodRemove));

  }
  const changeItensCart = (id) => {
    const produtos = serverProd

    const produtoFoiSelecionado = produtosSelecionados.find((produto) => produto.id == +id)
    const produtoDados = produtos.find((produto) => produto.id == +id);



    const produto = produtoFoiSelecionado ? produtoFoiSelecionado : produtoDados
    const produtosNaoSelecionados = produtosSelecionados.filter((item) => item.id !== +id);
    const novosProdutos = [...produtosNaoSelecionados, produto].sort((a, b) => a.id - b.id);
    // console.log(novosProdutos, produto)
    setProdutosSelecionados(novosProdutos);
    localStorage.setItem("produtosSelecionados", JSON.stringify(novosProdutos));

    // console.log(produtosNaoSelecionados, produtosSelecionados)
    // setModal(false);
    // console.log('novosProdutos')
  }
  // console.log(premiumSuper, opt.user.id)
  // src = `../,,/Assets/tiny/${prod.name}.png`;

  const GoInfo = (imageId, name) => {
    navigate(`/prodinfo/${imageId}`, {
      state: {
        serverProd: serverProd,
        produtosSelecionados: produtosSelecionados,
        name: name,
        imageId: imageId,
        loca: "/cuns"
      }
    });
  };
  const [donInfo, setDonInfo] = useState({})
  const Donate = (id, maxumum) => {
    setDonInfo({ id, maxumum })
    setDonatePriutModal(true)
  };


  return (
    <>
      <br />
      <div className={style.borders}>
        <br />
        <p className={style.p}>
          Представляем вашему вниманию две линейки влажных (консервированных) кормов классов ПРЕМИУМ и СУПЕР-ПРЕМИУМ, содержание натурального мяса в которых составляет 60-80%.
        </p>
        <br />
        <p className={style.p}>
          В основе корма лежит свежее охлажденное мясо высшего качества, а также субпродукты (рубец, легкое, печень, сердце, вымя и другие), овощи, необходимые витамины и микроэлементы, обеспечивающие собак и кошек, всем необходимым для полноценного развития и активной жизни. При разработке кормов учтены рекомендации лучших ветеринаров и сбалансировано сочетание белков, жиров и углеводов, необходимых для полноценной жизнедеятельности домашних питомцев.
        </p>
        <br />
        <p className={style.p}>
          Влажный корм изготовлен из натурального сырья, не содержит красителей, консервантов, ароматизаторов и усилителей вкуса, что обеспечивает отличную усвояемость и сводит к минимуму аллергические реакции. Содержит группу витаминов и минералов: Аргинин, Гистидин, Изолейцин, Лейцин, Лизин, Метионин, Метионин+цистеин, Фенилаланин, Фенилаланин+тирозин, Треонин, Триптофан, Валин, Линолевая кислота, Арахидоновая кислота, Кальций, Фосфор, Натрий, Хлорид, Магний, Медь, Железо, Марганец, Цинк, Витамин А, Витамин Е, Витамин ДЗ.
        </p>
      </div>
      <Title text="PREMIUM" />
      <br />


      <br />
      <div>
        <div className={style.produtos_container}>


          {!opt.auth && (
            premium.map(
              ({ photo, donation, name, price, id, scladqty, forwho, shcode, mnt }, index) => {
                const cart = produtosSelecionados.find((produto) => produto.id == +id);
                let cartb = false
                if (cart) {
                  cartb = true
                }
                let maximum2 = 0
                for (let i = 0; i < shops2.length; i++) {
                  const scladqty = productDots.find(pd => shops2[i].showed && pd.prod_sh == shcode && pd.prod_id == id && shops2[i].id == pd.shop_id)
                  if (scladqty) {
                    maximum2 = maximum2 + scladqty.scladqty
                    // console.log(shops2[i], scladqty)
                  }
                }
                // console.log(maximum2)

                return index <= amount && (<>

                  <div key={id} id={id} className={style.cards_produtos} style={{ opacity: maximum2 == 0 ? "0.5" : "1" }}>
                    {photo && (
                      // <img id={id} className={style.img} src={photo[0]} />
                      <div onClick={() => GoInfo(id, name)} className={style.imgcont} style={{ height: '250px' }}>
                        <LazyLoadImage
                          id={id}
                          className={style.img}
                          src={photo[0]}
                          alt="Example Image"
                          height="100%"
                          effect="blur"
                        // src="http://localhost:3000/static/images/example.jpg" // Замените на ваш путь к изображению
                        // width="600px"
                        />
                      </div>

                    )}
                    <p onClick={() => GoInfo(id, name)} id={id}>{name}</p>
                    {shops2.length > 0 && (shops2.map(pr => {
                      const scladqty = productDots.find(pd => pd.prod_sh == shcode && pd.prod_id == id && pr.id == pd.shop_id)
                      // console.log(productDots, scladqty)
                      return <td className={style.tabletd}>

                        <p style={{ fontWeight: 'normal' }}> {pr.adress}:  <b>{scladqty ? scladqty.scladqty : 0}{mnt}</b></p>



                      </td>
                    }))}
                    {/* <p id={id} className={style.price}><b>{price}RUB</b></p> */}

                    <div onClick={() => GoInfo(id, name)} className={style.vbadgecont}>
                      {forwho == 'Для собак' && (
                        <div href="#" className={style.vbadge} style={{ boxShadow: 'none', fontSize: '50px', border: 'none', 'background': 'none', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                          🐶<span></span>
                        </div>
                      )}
                      {forwho == 'Для кошек' && (
                        <div href="#" className={style.vbadge} style={{ boxShadow: 'none', fontSize: '50px', border: 'none', 'background': 'none', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                          🐱<span></span>
                        </div>
                      )}
                      {name == 'Мясные консервы «говядина с потрошками» 525г' && (
                        <div href="#" className={style.vbadge} style={{ height: '30px', 'background': '#04b31b', 'color': 'black', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                          ХИТ
                          <span></span>
                        </div>
                      )}
                      {Number(maximum2) > 0 &&
                        <div href="#" className={style.vbadge} style={{ height: '30px', 'background': 'var(--primary-color)', 'color': 'black', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                          {price}RUB
                          <span></span>
                        </div>}
                      {Number(maximum2) <= 0 &&
                        <div href="#" className={style.vbadge} style={{ height: '30px', 'background': 'var(--primary-color)', 'color': 'black', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                          Распродано!
                          <span></span>
                        </div>}

                      <div href="#" className={style.vbadge} style={{ height: '30px', 'background': '#20b6e8', 'color': 'black', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        Натуральный
                        <span></span>
                      </div>
                    </div>
                    <br/>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} className={style.container_price}>
                      {!cartb && Number(maximum2) > 0 && (<>

<div  className={style.doublexoom}>
                        <ButtonsModal className={style.vbadge11} id={id} max={maximum2} />

</div>
                        <Button style={{ height: '100%', margin: 0, marginLeft: '5px' }} text="Добавить В Корзину" onClick={() => changeItensCart(id)} />
                      </>
                      )}
                      {cartb && Number(maximum2) > 0 && (<>
                        <Button style={style.vbadge21} text="В Корзину"  onClick={() => setModalCart(true)} />
                        <Button style={style.vbadge21} text="Удалить из Корзины"  onClick={() => removeItem(id)} />
                      </>
                      )}

                    </div>
                    {Number(maximum2) > 0 && donation && (
                      <div style={{ display: 'flex', flexDirection: "row", alignItems: 'center', justifyContent: 'center' }} className={style.container_price}>

                        <Button text="🐈 Пожертвовать приюту 🐕" style={{ margin: '5px' }} onClick={() => Donate(id, maximum2)} />
                      </div>
                    )}

                    {/* <div href="#" style={{background:'var(--primary-color)'}} className={style.vbadge}>
                525гр
                <span></span>
              </div> */}
                  </div></>
                )
              }

            )
          )}



          {/* </div> */}
          <br />
        </div>
      </div>
      <Title text="SUPER PREMIUM" />
      <br />
      <div>
        <div className={style.produtos_container}>



          {!opt.auth && (
            premiumSuper.map(
              ({ src, forwho, donation, name, price, id, photo, scladqty, mnt, shcode }, index) => {
                const cart = produtosSelecionados.find((produto) => produto.id == +id);
                let cartb = false
                if (cart) {
                  cartb = true
                }
                let maximum2 = 0
                for (let i = 0; i < shops2.length; i++) {
                  const scladqty = productDots.find(pd => shops2[i].showed && pd.prod_sh == shcode && pd.prod_id == id && shops2[i].id == pd.shop_id)
                  if (scladqty) {
                    maximum2 = maximum2 + scladqty.scladqty
                    // console.log(shops2[i], scladqty)
                  }
                }
                // console.log(maximum2)
                return index <= amount && (<>

                  <div key={id} id={id} className={style.cards_produtos} style={{ opacity: maximum2 == 0 ? "0.5" : "1" }}>
                    {photo && (
                      // <img id={id} className={style.img} src={photo[0]} />
                      <div onClick={() => GoInfo(id, name)} className={style.imgcont} style={{ height: '250px' }}>
                        <LazyLoadImage
                          id={id}
                          className={style.img}
                          src={photo[0]}
                          alt="Example Image"
                          height="100%"
                          effect="blur"
                        // src="http://localhost:3000/static/images/example.jpg" // Замените на ваш путь к изображению
                        // width="600px"
                        />
                      </div>
                    )}
                    <p onClick={() => GoInfo(id, name)} id={id}>{name}</p>
                    {shops2.length > 0 && (shops2.map(pr => {
                      const scladqty = productDots.find(pd => pd.prod_sh == shcode && pd.prod_id == id && pr.id == pd.shop_id)
                      // console.log(productDots, scladqty)
                      return <td className={style.tabletd}>

                        <p style={{ fontWeight: 'normal' }}> {pr.adress}:  <b>{scladqty ? scladqty.scladqty : 0}{mnt}</b></p>



                      </td>
                    }))}
                    {/* <p id={id} className={style.price}>{price}RUB</p> */}
                    <div onClick={() => GoInfo(id, name)} className={style.vbadgecont}>
                      {forwho == 'Для собак' && (
                        <div href="#" className={style.vbadge} style={{ boxShadow: 'none', fontSize: '50px', border: 'none', 'background': 'none', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                          🐶<span></span>
                        </div>
                      )}
                      {forwho == 'Для кошек' && (
                        <div href="#" className={style.vbadge} style={{ boxShadow: 'none', fontSize: '50px', border: 'none', 'background': 'none', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                          🐱<span></span>
                        </div>
                      )}
                      {name == 'Мясные консервы «говядина с потрошками» 525г' && (
                        <div href="#" className={style.vbadge} style={{ height: '30px', 'background': '#04b31b', 'color': 'black', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                          ХИТ
                          <span></span>
                        </div>
                      )}
                      {Number(maximum2) > 0 &&
                        <div href="#" className={style.vbadge} style={{ height: '30px', 'background': 'var(--primary-color)', 'color': 'black', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                          {price}RUB
                          <span></span>
                        </div>}
                      {Number(maximum2) <= 0 &&
                        <div href="#" className={style.vbadge} style={{ height: '30px', 'background': 'var(--primary-color)', 'color': 'black', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                          Распродано!
                          <span></span>
                        </div>}

                      <div href="#" className={style.vbadge} style={{ height: '30px', 'background': '#20b6e8', 'color': 'black', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        Натуральный
                        <span></span>
                      </div>
                    </div>
                    <br/>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} className={style.container_price}>
                      {!cartb && Number(maximum2) > 0 && (<>

                        <div  className={style.doublexoom}>
                        <ButtonsModal className={style.vbadge11} id={id} max={maximum2} />

                        </div>
                        <Button style={{ height: '100%', margin: 0, marginLeft: '5px' }} text="Добавить В Корзину" onClick={() => changeItensCart(id)} />
                      </>
                      )}
                      {cartb && Number(maximum2) > 0 && (<>
                        <Button style={style.vbadge21.width} text="В Корзину"  onClick={() => setModalCart(true)} />
                        <Button style={style.vbadge21.width} text="Удалить из Корзины" onClick={() => removeItem(id)} />
                      </>
                      )}

                    </div>
                    {Number(maximum2) > 0 && donation && (
                      <div style={{ display: 'flex', flexDirection: "row", alignItems: 'center', justifyContent: 'center' }} className={style.container_price}>

                        <Button text="🐈 Пожертвовать приюту 🐕" style={{ margin: '5px' }} onClick={() => Donate(id, maximum2)} />
                      </div>
                    )}
                    {/* <div style={{ display: 'flex', flexDirection: "row", alignItems: 'center', justifyContent: 'center' }} className={style.container_price}>

                    <Button width={'100%'} text="Пожертвовать приюту" style={{ margin: '5px' }} onClick={() => Donate(id,maximum2)} />
                  </div> */}
                  </div></>
                )
              }
            )
          )}



        </div>
      </div>

      {/* </div> */}
      {donatePriutModal && (
        <ModalDonatePriuts
          id={donInfo.id}
          maxumum={donInfo.maximum}
        // setModal={setModal}
        // modal={modal}
        />
      )}
    </>
  );
};
export default Produtos;
