
import React from 'react'
// import Button from '../../../../Button/Button'
import style from './Modal.module.css'
// import Title from "../../Title";
import Button from '../../../../Button/Button'
import $api from "../../../../../http/index.ts";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { AiFillDelete } from "react-icons/ai";

import { useEffect, useState, useContext } from "react";
import { QtdContext } from "../../../../../context/qtdContext";
// import $api from "../../../../../http/index.ts";
// // import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
// // import 'react-tabs/style/react-tabs.css';
import axios from "axios";
// import ModalEdit from '../Components/Modal/Modal';
import { BACKURL } from '../../../../../conf.js'
import Loading from 'react-fullscreen-loading';
import { FileUploader } from "react-drag-drop-files";
const ModalEditProduct = ({ id, suh }) => {


  // const produtosCart = JSON.parse(localStorage.getItem("pet"));
  // const { setSclad,setServerProd, auth, setAuth } = useContext(QtdContext);

  const { servOrders, sclad, setSclad, productGroups, setServerProd, serverProd, modalEditProduct, setModalEditProduct } = useContext(QtdContext);
  const [newValue, setNeValue] = useState('')
  const [files, setFiles] = useState([]);
  const [fileURLs, setFileURLs] = useState([]);
  const closeModal = ({ target }) => {
    // console.log(target)
    target.id === "container124" && setModalEditProduct(false);
    // target.innerText === "ОТМЕНИТЬ" && setModal(false);
  };
  // const { modal, setModal } = useState(false);
  const [loading, setLoading] = useState(false)
  const [imageFile, setImageFile] = useState(null);
  // useEffect(() => {
  //     window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });

  // }, [])
  console.log(id)
  // const [file, setFile] = useState(null);
  // const handleChange = (file) => {
  //   if (file.type === 'image/heic' || file.type === 'image/heif') {
  //     alert('HEIC/HEIF images are not supported. Please convert your image to JPEG or PNG.');
  //   }
  //   setFile(file);
  //   setFileURL(URL.createObjectURL(file))
  //   // console.log(file)
  // };

  // setFileURL(product.photo[0])
  // const [fileURL, setFileURL] = useState(product.photo[0]);

  const [newProd, setNewProd] = useState(
    {
      type: '',
      name: "",
      kategory: '',
      podkategory: '',
      scladqty: '',
      price: '',
      shcode: '',
      forwho: '',
      suska: {
        sh50: '',
        price50: '',
        sh100: '',
        price100: '',
        sh1000: '',
        price1000: '',
      }
      ,
      about: '',
      forwho: '',
      photo: '',
      mnt: '',
      brand: '',
      recomendation: ''
    }
  )
  useEffect(() => {


      const product = serverProd.find(pr => pr.id == id)
      console.log(product)
      setFileList(product.photo.map(url => ({ file: null, url }))); 
      setNewProd({ ...product, photo: [product.photo[0]], about: product.text })
    
  }, [])

  // console.log(product)
  const [err, setErr] = useState({})
  const create = async (e) => {
    try {
      e.preventDefault();
  
      let t = {
        name: false,
        kategory: false,
        podkategory: false,
        scladqty: false,
        forwho: false,
        price: false,
        shcode: false,
        text: false,
        photo: false,
        recomendation: false,
        mnt: false,
        brand: false
      };
  
      if (!newProd.name) {
        t.name = 'Наименование обязательно к заполнению!';
      }
      if (!newProd.kategory && newProd.forwho !== 'Для грызунов' && newProd.forwho !== 'Для птиц') {
        t.kategory = 'Категория обязательна к выбору!';
      }
      if (newProd.kategory === 'Каталог товаров' && newProd.forwho !== 'Для грызунов' && newProd.forwho !== 'Для птиц') {
        if (!newProd.podkategory) {
          t.podkategory = 'Подкатегория обязательна к выбору!';
        }
      }
      if (!newProd.brand) {
        t.brand = 'Brand обязательно к заполнению!';
      }
      if (!newProd.mnt) {
        t.mnt = 'Mnt обязательно к заполнению!';
      }
      if (!newProd.price) {
        t.price = 'Розничная цена обязательна к заполнению!';
      }
      if (!newProd.forwho) {
        t.forwho = 'Описание обязательно к заполнению!';
      }
      if (!newProd.shcode) {
        t.shcode = 'ШК обязательно к заполнению!';
      }
      if (!(newProd.scladqty >= 0)) {
        t.scladqty = 'Должно быть число >= 0!';
      }
  
      console.log(t);
      setErr(t);
  
      if (
        newProd.name &&
        newProd.mnt &&
        newProd.price &&
        newProd.brand &&
        newProd.forwho &&
        newProd.shcode &&
        !t.scladqty &&
        !t.mnt &&
        !t.brand &&
        !t.name &&
        !t.kategory &&
        !t.price &&
        !t.text &&
        !t.shcode &&
        !t.forwho
      ) {
        const formData = new FormData();
  
        // ✅ Используем fileList вместо files
        fileList.forEach((item, index) => {
          formData.append(`images`, item.file); // `images` - массив на сервере
        });
  
        formData.append('name', newProd.name);
        formData.append('forwho', newProd.forwho);
        formData.append('photo', JSON.stringify(fileList.map(item => item.url))); // ✅ Теперь берем URL из fileList
        formData.append('podkategory', newProd.podkategory);
        formData.append('kategory', newProd.kategory);
        formData.append('price', newProd.price);
        formData.append('about', newProd.about);
        formData.append('shcode', newProd.shcode);
        formData.append('type', newProd.type);
        formData.append('recomendation', newProd.recomendation);
        formData.append('brand', newProd.brand);
        formData.append('mnt', newProd.mnt);
        formData.append('suska', JSON.stringify(newProd.suska));
  
        setLoading(true);
  
        const response = await $api.post(`${BACKURL}/updateprod/${id}`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });
  
        if (response) {
          console.log(response.data);
          setSclad(sclad.map(pr => (pr.id === response.data.id ? response.data : pr)));
          setServerProd(serverProd.map(pr => (pr.id === response.data.id ? response.data : pr)));
          setModalEditProduct(false);
        } else {
          console.error('Ошибка при загрузке изображения');
        }
  
        setLoading(false);
        alert('Готово!');
      }
    } catch (e) {
      setLoading(false);
      alert('Ошибка!');
    }
  };
  

  // const removeImage = (index) => {
  //   console.log(files[0])
  //   setFiles(prevFiles => prevFiles.filter((_, i) => i !== index));
  //   setFileURLs(prevURLs => prevURLs.filter((_, i) => i !== index));
  // };
  // const handleChange = (selectedFiles) => {
  //   const newFiles = Array.from(selectedFiles); // Преобразуем FileList в массив
  //   const newFileURLs = newFiles.map(file => URL.createObjectURL(file));

  //   setFiles(prevFiles => [...prevFiles, ...newFiles]);
  //   setFileURLs(prevURLs => [...prevURLs, ...newFileURLs]);
  // };
  const [fileList, setFileList] = useState([]); // [{ file: File, url: "blob:https://..." }]

const handleChange = (selectedFiles) => {
    const newFiles = Array.from(selectedFiles).map(file => ({
        file,
        url: URL.createObjectURL(file)
    }));

    setFileList(prevList => [...prevList, ...newFiles]);
};

const removeImage = (index) => {
    setFileList(prevList => prevList.filter((_, i) => i !== index));
};
  return (
    <>
      {modalEditProduct && (
        <div onClick={closeModal} id="container124" className={style.modal_container} >

          <div className={style.modal} style={{ background: 'antiquewhite', height: '70%', padding: '20px' }}>

            <div className={style.uploaddiv}>

              <div className={style.imgcont} style={{ display: "flex", flexWrap: "wrap" }}>
                {/* {fileURLs.map((url, index) => (<>
                  <LazyLoadImage
                    key={index}
                    className={style.img}
                    src={url}
                    alt={`Preview ${index + 1}`}
                    height="100%"
                    effect="blur"
                    style={{ margin: "5px", width: "100px", height: "100px" }}
                  />
                  <AiFillDelete
                    onClick={() => removeImage(index)}
                    style={{
                      position: "relative",
                      // bottom: "40px",
                      // right: "20px",
                      // background: "red",
                      color: "red",
                      border: "none",
                      borderRadius: "50%",
                      width: "20px",
                      height: "20px",
                      cursor: "pointer",
                      fontSize: "14px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center"
                    }}
                  >

                  </AiFillDelete>
                </>
                ))} */}
                {fileList.map((item, index) => (
  <div key={index} style={{ display: "inline-block", position: "relative" }}>
    <LazyLoadImage
      className={style.img}
      src={item.url}
      alt={`Preview ${index + 1}`}
      height="100%"
      effect="blur"
      style={{ margin: "5px", width: "100px", height: "100px" }}
    />
    <AiFillDelete
      onClick={() => removeImage(index)}
      style={{
        position: "absolute",
        top: 0,
        right: 0,
        color: "red",
        borderRadius: "50%",
        width: "20px",
        height: "20px",
        cursor: "pointer",
        fontSize: "14px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    />
  </div>
))}

              </div>

              <FileUploader handleChange={handleChange} name="files" multiple />
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center', textAlign: 'center', flexDirection: 'row', flexWrap: 'wrap' }}>
              <div className={style.inputdiv} style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center', textAlign: 'center', flexDirection: 'column' }}>
                <label style={{ color: err.name ? 'red' : 'black' }} for="name">Введите Наименование:</label>

                <input value={newProd.name} type="text" id="name" name="name" onChange={(event) => {
                  setNewProd({ ...newProd, name: event.target.value });
                }} />
              </div>

              <div className={style.inputdiv} style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center', textAlign: 'center', flexDirection: 'column' }}>
                <label style={{ color: err.type ? 'red' : 'black' }} for="pet-select">Выберите Категорию:</label>

                <select style={{ height: "50px" }} value={newProd.type} name="pets" id="pet-select" onChange={(event) => {
                  if (event.target.value == "Наша продукция") {
                    setNewProd({ ...newProd, type: event.target.value, brand: "KOTOPES" });

                  } else {
                    setNewProd({ ...newProd, type: event.target.value });
                  }
                }}>
                  <option value="">--Выберите тип товара--</option>
                  <option value="Наша продукция">Наша продукция</option>
                  <option value="Каталог товаров">Каталог товаров</option>
                </select>

                {/* {err.kategory && (
                                     <p style={{ color: "red" }}>{err.kategory}</p>
                                 )} */}
              </div>
              <div className={style.inputdiv} style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center', textAlign: 'center', flexDirection: 'column' }}>
                <label style={{ color: err.forwho ? 'red' : 'black' }} for="pet-select">Для кого:</label>

                <select style={{ height: "50px" }} value={newProd.forwho} name="pets" id="pet-select" onChange={(event) => {
                  setNewProd({ ...newProd, forwho: event.target.value });
                }}>
                  <option value="">--Выберите для кого продукция--</option>

                  <option value="Для кошек">Для кошек</option>
                  <option value="Для собак">Для собак</option>
                  <option value="Для кошек и собак">Для кошек и собак</option>
                  <option value="Для грызунов">Для грызунов</option>
                  <option value="Для птиц">Для птиц</option>
                </select>

                {/* {err.forwho && (
                                     <p style={{ color: "red" }}>{err.forwho}</p>
                                 )} */}
              </div>
              {newProd.type && newProd.forwho && (<>
                <div className={style.inputdiv} style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center', textAlign: 'center', flexDirection: 'column' }}>
                  <label style={{ color: err.kategory ? 'red' : 'black' }} for="pet-select">Выберите Категорию:</label>

                  <select style={{ height: "50px" }} value={newProd.kategory} name="pets" id="pet-select" onChange={(event) => {
                    setNewProd({ ...newProd, kategory: event.target.value });
                  }}>
                    <option value="">--Выберите Категорию--</option>
                    {newProd.type == "Наша продукция" && (<>
                      <option value="фарш">фарш</option>
                      <option value="мясные консервы для кошек">мясные консервы для кошек</option>
                      <option value="мясные консервы для собак">мясные консервы для собак</option>
                      <option value="сушенная продукция для собак">сушенная продукция для собак</option>
                      <option value="отдельная мороженная продукция">отдельная мороженная продукция</option>
                      <option value="рацион">рацион</option>
                    </>
                    )}
                    {newProd.type == "Каталог товаров" && (
                      <>
                        <option value="Уход">Уход</option>
                        <option value="ВетАптека">ВетАптека</option>
                        <option value="Аксессуары">Аксессуары</option>
                        <option value="сухой корм">Cухой корм</option>
                        <option value="Паучи">Паучи</option>
                      </>
                    )}
                  </select>

                </div>
                {newProd.type == 'Каталог товаров' && (
                  <div className={style.inputdiv} style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center', textAlign: 'center', flexDirection: 'column' }}>
                    <label style={{ color: err.podkategory ? 'red' : 'black' }} for="pet-select">Выберите Подкатегорию:</label>

                    <select style={{ height: "50px" }} value={newProd.podkategory} name="pets" id="pet-select" onChange={(event) => {
                      setNewProd({ ...newProd, podkategory: event.target.value });
                    }}>
                      <option value="">--Выберите Подкатегорию--</option>
                      {newProd.kategory == 'ВетАптека' && (
                        <>
                          <option value="От блох и клещей">От блох и клещей</option>
                          <option value="Витамины">Витамины</option>
                          <option value="Полость рта">Полость рта</option>
                          <option value="Другое">Другое</option>
                        </>
                      )}
                      {newProd.kategory == 'Аксессуары' && (
                        <>
                          <option value="Игрушки">Игрушки</option>
                          <option value="Амуниция">Амуниция</option>
                          <option value="Лежанки/Домики">Лежанки/Домики</option>
                          <option value="Другое">Другое</option>
                        </>
                      )}
                      {newProd.kategory == 'Уход' && (
                        <>
                          <option value="Наполнитель">Наполнитель</option>
                          <option value="Шампунь">Шампунь</option>
                          <option value="Пеленки">Пеленки</option>
                          <option value="Другое">Другое</option>
                        </>
                      )}
                      {(newProd.kategory == 'Паучи' || newProd.kategory == 'сухой корм') && (
                        <>
                          <option value="С говядиной">С говядиной</option>
                          <option value="С курицей">С курицей</option>
                          <option value="С индейкой">С говядиной</option>
                          <option value="С теленком">С теленком</option>
                          <option value="С кроликом">С кроликом</option>
                          <option value="С лососем">С лососем</option>
                          <option value="С треской">С треской</option>
                          <option value="Со свининой">Со свининой</option>
                          <option value="Другое">Другое</option>
                        </>
                      )}
                    </select>

                    {/* {err.podkategory && (
                                             <p style={{ color: "red" }}>{err.podkategory}</p>
                                         )} */}
                  </div>
                )}

              </>
              )}


              {/* {newProd.kategory !== 'сушенная продукция для собак' && (<> */}
              <div className={style.inputdiv} style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center', textAlign: 'center', flexDirection: 'column' }}>
                <label style={{ color: err.mnt ? 'red' : 'black' }} for="pet-select">Выберите ед:</label>

                <select style={{ height: "50px" }} value={newProd.mnt} name="pets" id="pet-select" onChange={(event) => {
                  setNewProd({ ...newProd, mnt: event.target.value });
                }}>
                  <option value="">--Выберите ед.--</option>

                  <option value="шт">шт</option>
                  <option value="кг">кг</option>
                  <option value="л">л</option>
                  <option value="мл">мл</option>
                </select>

                {/* {err.forwho && (
                                     <p style={{ color: "red" }}>{err.forwho}</p>
                                 )} */}
              </div>
              {/* </>)} */}
              {/* {newProd.kategory == 'сушенная продукция для собак' && (<>
                <div className={style.inputdiv} style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center', textAlign: 'center', flexDirection: 'column' }}>
                  <label style={{ color: err.suska ? 'red' : 'black' }} for="price">Цена 50г:</label>
                  <input value={newProd.suska.price50} type="text" id="price" name="price" onChange={(event) => {
                    setNewProd({ ...newProd, suska: { ...newProd.suska, price50: event.target.value } });
                  }} />

                </div>
                <div className={style.inputdiv} style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center', textAlign: 'center', flexDirection: 'column' }}>
                  <label style={{ color: err.suska ? 'red' : 'black' }} for="shcode">Введите ШК для 50г:</label>
                  <input value={newProd.suska.sh50} type="text" id="shcode" name="shcode" onChange={(event) => {
                    setNewProd({ ...newProd, suska: { ...newProd.suska, sh50: event.target.value } });
                  }} />

                </div>
                <div className={style.inputdiv} style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center', textAlign: 'center', flexDirection: 'column' }}>
                  <label style={{ color: err.suska ? 'red' : 'black' }} for="price">Цена 100г:</label>
                  <input value={newProd.suska.price100} type="text" id="price" name="price" onChange={(event) => {
                    setNewProd({ ...newProd, suska: { ...newProd.suska, price100: event.target.value } });
                  }} />

                </div>
                <div className={style.inputdiv} style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center', textAlign: 'center', flexDirection: 'column' }}>
                  <label style={{ color: err.suska ? 'red' : 'black' }} for="shcode">Введите ШК для 100г:</label>
                  <input value={newProd.suska.sh100} type="text" id="shcode" name="shcode" onChange={(event) => {
                    setNewProd({ ...newProd, suska: { ...newProd.suska, sh100: event.target.value } });
                  }} />

                </div>
                <div className={style.inputdiv} style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center', textAlign: 'center', flexDirection: 'column' }}>
                  <label style={{ color: err.suska ? 'red' : 'black' }} for="price">Цена 1кг:</label>
                  <input value={newProd.suska.price1000} type="text" id="price" name="price" onChange={(event) => {
                    setNewProd({ ...newProd, suska: { ...newProd.suska, price1000: event.target.value } });
                  }} />

                </div>
                <div className={style.inputdiv} style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center', textAlign: 'center', flexDirection: 'column' }}>
                  <label style={{ color: err.suska ? 'red' : 'black' }} for="shcode">Введите ШК для 1кг:</label>
                  <input value={newProd.suska.sh1000} type="text" id="shcode" name="shcode" onChange={(event) => {
                    setNewProd({ ...newProd, suska: { ...newProd.suska, sh1000: event.target.value } });
                  }} />

                </div>



              </>)} */}

              {newProd.type == 'Каталог товаров' && (
                <div className={style.inputdiv} style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center', textAlign: 'center', flexDirection: 'column' }}>
                  <label style={{ color: err.brand ? 'red' : 'black' }} for="brand">Введите бренд:</label>
                  <input value={newProd.brand} type="text" id="brand" name="brand" onChange={(event) => {
                    setNewProd({ ...newProd, brand: event.target.value });
                  }} />
                  {/* {err.price && (
                                     <p style={{ color: "red" }}>{err.price}</p>
                                 )} */}
                </div>
              )}
              {/* {newProd.kategory !== 'сушенная продукция для собак' && (<> */}
              <div className={style.inputdiv} style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center', textAlign: 'center', flexDirection: 'column' }}>
                <label style={{ color: err.price ? 'red' : 'black' }} for="price">Введите розничную цену:</label>
                <input value={newProd.price} type="text" id="price" name="price" onChange={(event) => {
                  setNewProd({ ...newProd, price: event.target.value });
                }} />
                {/* {err.price && (
                                     <p style={{ color: "red" }}>{err.price}</p>
                                 )} */}
              </div>
              <div className={style.inputdiv} style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center', textAlign: 'center', flexDirection: 'column' }}>
                <label style={{ color: err.shcode ? 'red' : 'black' }} for="shcode">Введите ШК:</label>
                <input value={newProd.shcode} type="text" id="shcode" name="shcode" onChange={(event) => {
                  setNewProd({ ...newProd, shcode: event.target.value });
                }} />
                {/* {err.shcode && (
                                     <p style={{ color: "red" }}>{err.shcode}</p>
                                 )} */}
              </div>
              {/* </>)} */}
              {/* <div className={style.inputdiv} style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center', textAlign: 'center', flexDirection: 'column' }}>
                                 <label style={{ color: err.recomendation ? 'red' : 'black' }} for="text">Введите рекомендации÷ товара:</label>
                                 <textarea value={newProd.recomendation} type="text" style={{ height: '200px' }} id="recomendation" name="text" onChange={(event) => {
                                     setNewProd({ ...newProd, recomendation: event.target.value });
                                 }} />
                              
                             </div> */}
              <div className={style.inputdiv} style={{ display: 'flex', width: '100%', justifyContent: 'space-around', alignItems: 'center', textAlign: 'center', flexDirection: 'column' }}>
                <label style={{ color: err.about ? 'red' : 'black' }} for="text">Введите Описание для нового товара:</label>
                <textarea value={newProd.about} type="text" style={{ height: '250px', width: '80%' }} id="about" name="text" onChange={(event) => {
                  setNewProd({ ...newProd, about: event.target.value });
                }} />
                {/* {err.about && (
                                     <p style={{ color: "red" }}>{err.about}</p>
                                 )} */}
              </div>

            </div>



            {!loading && (
              <Button
                text="Изменить товар"

                background="var(--primary-color)"
                color="white"
                width="100%"
                onClick={create}
              />
            )}
            {loading && (
              <Button
                text="Изменяем..."

                background="var(--primary-color)"
                color="white"
                width="100%"
                disabled={true} />
            )}

          </div>

        </div>

        // </div>
      )}
      {loading && (
        <Loading loading background="rgba(255, 165, 0,0.75)" loaderColor="#3498db"></Loading>
      )}
    </>
  );
};

export default ModalEditProduct;
